import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
var Types;
(function (Types) {
    Types["INITIAL"] = "INITIAL";
    Types["LOGIN"] = "LOGIN";
    Types["LOGOUT"] = "LOGOUT";
    Types["REFRESH"] = "REFRESH";
    Types["ERROR"] = "ERROR";
    Types["REGISTER"] = "REGISTER";
})(Types || (Types = {}));
// ----------------------------------------------------------------------
const initialState = {
    isInitialized: false,
    isAuthenticated: false,
    user: null,
    error: null,
};
const reducer = (state, action) => {
    if (action.type === Types.INITIAL) {
        return {
            isInitialized: true,
            isAuthenticated: action.payload.isAuthenticated,
            user: action.payload.user,
            error: null,
        };
    }
    if (action.type === Types.REFRESH) {
        return {
            ...state,
            isAuthenticated: true,
            error: null,
        };
    }
    if (action.type === Types.LOGIN) {
        return {
            ...state,
            isAuthenticated: true,
            user: action.payload.user,
            error: null,
        };
    }
    if (action.type === Types.REGISTER) {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
            error: null,
        };
    }
    if (action.type === Types.LOGOUT) {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
            error: null,
        };
    }
    if (action.type === Types.ERROR) {
        return {
            ...state,
            error: action.payload.error,
        };
    }
    return state;
};
// ----------------------------------------------------------------------
export const AuthContext = createContext(null);
export function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const storageAvailable = localStorageAvailable();
    // REFRESH
    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post('/refresh', {
                refreshToken,
            });
            const { accessToken } = response.data;
            const newRefreshToken = response.data.refreshToken;
            setSession(accessToken);
            localStorage.setItem('refreshToken', newRefreshToken); // Save the refresh token
            dispatch({
                type: Types.REFRESH,
                payload: {},
            });
        }
        catch (error) {
            console.error(error);
            dispatch({
                type: Types.LOGOUT,
            });
        }
    };
    const initialize = useCallback(async () => {
        try {
            const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
            if (accessToken) {
                setSession(accessToken);
                if (isValidToken(accessToken)) {
                    const response = await axios.get('/me');
                    const user = response.data.data;
                    dispatch({
                        type: Types.INITIAL,
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    });
                }
                else {
                    const refreshToken = storageAvailable ? localStorage.getItem('refreshToken') : '';
                    if (refreshToken) {
                        await refreshAccessToken(refreshToken);
                    }
                    else {
                        dispatch({
                            type: Types.INITIAL,
                            payload: {
                                isAuthenticated: false,
                                user: null,
                            },
                        });
                    }
                }
            }
            else {
                dispatch({
                    type: Types.INITIAL,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        }
        catch (error) {
            localStorage.clear();
            console.error(error);
            dispatch({
                type: Types.INITIAL,
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });
        }
    }, [storageAvailable]);
    const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
    useEffect(() => {
        initialize();
    }, [initialize, accessToken]);
    const login = useCallback(async (email, password) => {
        try {
            const response = await axios.post('/login', {
                email,
                password,
            });
            window.location.reload();
            const { tokens, user } = response.data.data;
            setSession(tokens.accessToken);
            localStorage.setItem('refreshToken', tokens.refreshToken); // Save the refresh token
            dispatch({
                type: Types.LOGIN,
                payload: {
                    user,
                },
            });
        }
        catch (error) {
            console.error(error);
            const errorPayload = {
                error: {
                    statusCode: error.statusCode,
                    message: error.message || error,
                },
            };
            dispatch({ type: Types.ERROR, payload: errorPayload });
        }
    }, []);
    const register = useCallback(async (data) => {
        await axios.post(`users/register?language=${data.language}`, data.user);
        dispatch({
            type: Types.REGISTER,
            payload: {
                user: null,
            },
        });
    }, []);
    // LOGOUT
    const logout = useCallback(() => {
        try {
            axios.get('/logout');
            setSession(null);
            localStorage.removeItem('refreshToken'); // Remove the refresh token
            dispatch({
                type: Types.LOGOUT,
            });
        }
        catch (error) {
            console.error(error);
        }
    }, []);
    const memoizedValue = useMemo(() => ({
        isInitialized: state.isInitialized,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        error: state.error,
        method: 'jwt',
        register,
        login,
        logout,
    }), [state.isAuthenticated, state.isInitialized, state.user, state.error, login, register, logout]);
    return _jsx(AuthContext.Provider, { value: memoizedValue, children: children });
}
